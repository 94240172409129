import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar, faQuestionCircle } from '@fortawesome/free-regular-svg-icons';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

import('./style.sass')

const ChildrenNotes = () => (
  <div className="children-notes">
    <div className="bg-icon"><FontAwesomeIcon icon={faQuestionCircle} /></div>
    <div className="inner-content">
      <div className="notes-title">Extra information</div>
      <div className="note">
        <FontAwesomeIcon icon={faStar} /> Extra appoint bed: <strong>40€</strong>
      </div>
      <div className="note">
        <FontAwesomeIcon icon={faStar} /> Children from 0 to 2 years: <strong>free</strong> (sharing room with adults)
      </div>
      <div className="note">
        <FontAwesomeIcon icon={faStar} /> Children from 3 to 10 years: <strong>50%</strong> reduction (sharing the room with adults)
      </div>
    </div>
  </div>
)

export default ChildrenNotes