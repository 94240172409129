import React from 'react'

import './style.sass'

import facility_1 from '../../images/facilities/swimming-pool.png'
import facility_2 from '../../images/facilities/no-smooking.png'
import facility_3 from '../../images/facilities/parking.png'
import facility_4 from '../../images/facilities/airport-shuttle.png'
import facility_5 from '../../images/facilities/breakfast.png'
import facility_6 from '../../images/facilities/wifi.png'

const MainFacilities = () => (
  <div className="main-facilities">
    <h3>Hotel Facilities</h3>  
    <ul>
      <li>
        <div className="facility-img">
          <img src={facility_1} alt=""/>
        </div>
        <div className="facility-title">Swimming Pool</div>
      </li>
      <li>
        <div className="facility-img">
          <img src={facility_2} alt=""/>
        </div>
        <div className="facility-title">No smooking rooms</div>
      </li>
      <li>
        <div className="facility-img">
          <img src={facility_3} alt=""/>
        </div>
        <div className="facility-title">Free parking</div>
      </li>
      <li>
        <div className="facility-img">
          <img src={facility_4} alt=""/>
        </div>
        <div className="facility-title">Airport shuttle</div>
      </li>
      <li>
        <div className="facility-img">
          <img src={facility_5} alt=""/>
        </div>
        <div className="facility-title">Breakfast</div>
      </li>
      <li>
        <div className="facility-img">
          <img src={facility_6} alt=""/>
        </div>
        <div className="facility-title">Free Wi-Fi</div>
      </li>
    </ul>  
  </div>
)

export default MainFacilities