import React from 'react'
import SlickSlider from '../SlickSlider'
import uuid from 'uuid'

import './style.sass'

const RoomsList = ({rooms}) => (
  <ul className="rooms-list">
      {rooms.map(({node}) => {
        return (
          <li key={uuid.v4()}>
            <div className="slider-wrapper">
              <SlickSlider slides={node.frontmatter.images} link={node.frontmatter.path}/>
            </div>
            <div className="room-info">
              <a className="inner-content" href={node.frontmatter.path} >
                <div className="room-title">
                  {node.frontmatter.title}
                </div>
                <div className="room-excerpt">
                  Patio, Safety Deposit Box, Air conditioning, Seating Area, Heating, Dressing Room, Sofa, Soundproofing, Tile/Marble floor, Private pool, Wardrobe or closet ...
                </div>
                <div className="room-price">
                  Price: <span>{node.frontmatter.price}</span> / Night
                </div>
              </a> 
            </div>
          </li>
        )
      }
      )}
    </ul>
)

export default RoomsList;