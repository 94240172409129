import React from 'react'
import Slider from "react-slick"
import uuid from 'uuid'
import { Link } from 'gatsby';

import "slick-carousel/slick/slick.css" 
import "slick-carousel/slick/slick-theme.css"
import BgImage from '../BgImage';

import "./style.sass"

const SlickSlider = ({slides, link, options}) => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: false,
    ...options
  }

  return (
    <Slider {...settings}>
        {slides.map(slide => (
          <div key={uuid.v4()}>
            <Link to={link}>
              <BgImage className='room-slide' image={slide}/>
            </Link>
          </div>
        ))}
    </Slider>
  )
}

export default SlickSlider